<template>
  <div class="container">
    <div class="tax-guide col-12">
      <h6 class="text-blue">{{ $t("filingTaxReturn") }}</h6>
      <p>
        {{ $t("filingTaxReturnText") }}
      </p>
      <h6 class="text-blue mt-5 mb-5">
        {{ $t("butTaxReturn") }}
      </h6>
      <p class="mb-4">
        {{ $t("simpleRules") }}
        <span class="font-weight-bold text-dark-blue">{{
          $t("taxPayer")
        }}</span>
        {{ $t("mustTaxReturn") }}
        <span class="font-weight-bold text-dark-blue">{{
          $t("taxReturnDoc")
        }}</span>
        {{ $t("and") }}
        <span class="font-weight-bold text-dark-blue">
          {{ $t("mandatoryFile") }}</span
        >{{ $t("in") }}
        <span class="font-weight-bold text-dark-blue">{{
          $t("taxReturnSection")
        }}</span
        >:
      </p>
      <ul>
        <li>{{ $t("additionalIncome") }}</li>
        <li>
          {{ $t("rentingAndLeasing") }}
        </li>
        <li>
          {{ $t("selfEmployment") }}
        </li>
        <li>
          {{ $t("socialBenefits") }}
        </li>
        <li>
          {{ $t("contractsOverlap") }}
        </li>
        <li>
          {{ $t("oldAgePension") }}
        </li>
        <li>
          {{ $t("educationAllowance") }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.tax-guide {
  padding: 160px 0px;
  text-align: justify;

  ul {
    li {
      &:not(:last-child) {
        margin-bottom: 16px !important;
      }
    }
  }
}
</style>